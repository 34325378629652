import { Pipe, PipeTransform, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CountryCodeType, LanguageService } from '@aston/foundation';

import { AppStoreSelectors } from '../../root-store/app-store';

@Pipe({
	name: 'translateCountryName',
})
export class TranslateCountryNamePipe implements PipeTransform {

	private store = inject(Store);
	private languageService = inject(LanguageService);

	transform(countryCode: CountryCodeType): Observable<string> {
		return of(this.languageService.getCurrentLanguage()).pipe(
			switchMap(lang => this.store.select(AppStoreSelectors.selectCountriesDictionary(lang))),
			map(countries => {
			return countries[countryCode]
		}));
	}
}
