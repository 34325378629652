
import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { AccountingDocumentPaymentTypeCode, AccountingDocumentTypeCode } from 'apps/federation/src/app/accounting-module/enums';

export type MergedAccountingDocumentType = 	AccountingDocumentTypeCode |
											AccountingDocumentPaymentTypeCode;

export interface IAccountingDocumentTypeBase {
	type?: AccountingDocumentTypeCode;
	paymentType?: AccountingDocumentPaymentTypeCode;
}

export interface IAccountingDocumentTypePipeOptions {
	isFull?: boolean;
	displaySubType?: boolean;
	displayMiscType?: boolean;
}

@Pipe({
	name: 'accountingType',
	pure: false
})
export class AccountingDocumentTypePipe extends TranslatePipe implements PipeTransform {

	defaultOptions: IAccountingDocumentTypePipeOptions = {
		isFull: false,
		displaySubType: false,
	};

	transform<T extends IAccountingDocumentTypeBase>(document: T | string, options: IAccountingDocumentTypePipeOptions): string {
		options = {
			...this.defaultOptions,
			...options
		};

		// When displaySubType but displayMiscType is unknown, assume we want displayMiscType
		if (options.displaySubType && options.displayMiscType === undefined) {
			options.displayMiscType = true;
		}

		let documentType: string;

		if (typeof document === 'string') {
			documentType = document;
		} else  {
			documentType = document.type;

			if (options.displaySubType) {
				documentType = (document.type === AccountingDocumentTypeCode.Cashing && !!document.paymentType) ?
								document.paymentType : document.type;
			}
		}

		return super.transform(`AccountingDocuments.Types.${options.isFull ? 'Full' : 'Short'}.${documentType}`);
	}
}
