import { Pipe, PipeTransform, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { UserClearanceLevel } from '../../authentication-module/enums';
import { AppStoreSelectors } from '../../root-store/app-store';

@Pipe({
	name: 'clearedLevel',
})
export class ClearedLevelPipe implements PipeTransform {

	private store = inject(Store);

	transform(level: UserClearanceLevel): Observable<boolean> {
		return this.store.select(AppStoreSelectors.selectClearsLevel(level)).pipe(first());
	}
}
