import { Pipe, PipeTransform } from '@angular/core';

import { INotificationItem } from '../models';
import { NotificationStatus } from '../enums';


export type NotificationStatusPipeArgsType = 'badgeClass';

@Pipe({
	name: 'notificationStatus',
})
export class NotificationStatusPipe implements PipeTransform {

	transform(notification: INotificationItem, args?: NotificationStatusPipeArgsType): string {

		if (args) {
			switch (notification.status) {
				case NotificationStatus.Validated:
					return 'success';
				case NotificationStatus.Refused:
					return 'danger';
				default:
					return 'secondary';
			}
		}

		return '';
	}

}
